import React from 'react';
import cuckooLogo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="cuckoo__footer section__padding">
    {/* <div className="cuckoo__footer-heading">
      <h1 className="gradient__text">Do you want to improve your customer retention?</h1>
    </div>

    <div className="cuckoo__footer-btn">
      <p>Request demo</p>
    </div> */}

    <div className="cuckoo__footer-links">
      <div className="cuckoo__footer-links_logo">
        <img src={cuckooLogo} alt="cuckoo_logo" />
        <p>Cuckoo Technologies Inc. <br /> All Rights Reserved</p>
      </div>
      <div className="cuckoo__footer-links_div">
        <h4>Links</h4>
        <p><a href="/#developer">API Documentations</a></p>
        <p>Admin Dashboard</p>
      </div>
      <div className="cuckoo__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p><a href="/privacy">Privacy Policy</a></p>
        <p><a href="/#contact">Contact</a></p>
      </div>
      <div className="cuckoo__footer-links_div">
        <h4>Get in touch</h4>
        <p> </p>
        <p>info@cuckoorewards.com</p>
      </div>
    </div>

    <div className="cuckoo__footer-copyright">
      <p>@2021 Cuckoo Technologies Inc. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
