import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useCookies } from 'react-cookie';
// eslint-disable-next-line import/no-useless-path-segments
import { Footer, Stocks, Program, Travel, GiftCard, Features, Contact, Developer } from './../../containers';
// eslint-disable-next-line import/no-useless-path-segments
import { Navbar } from './../../components';
import space from '../../assets/space.png';
import './header.css';

const Header = () => {
  // const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['emailid']);
  const [emailData, setEmailData] = useState('');
  const updateEmailInput = (e) => {
    setEmailData(e.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const contactUs = () => {
    // event.preventDefault();
    // navigate('#contact', { state: emailData });
    setCookie('emailid', emailData, { path: '/' });
    console.log('Get Started Clicked with email: ', emailData, cookies.emailid);
    setEmailData('');
  };

  return (
    <>
      <Navbar />
      <div className="hero-image">
        <div className="cuckoo__header section__padding" id="home">
          <div className="cuckoo__header-content">
            <h1 className="gradient__text">Boost customer retention with innovative ways to use reward points</h1>
            <p>Provide your customers with exciting ways to use their rewards points including stocks and crypto. They will love it, and you will see your retention jump up.</p>

            <div className="cuckoo__header-content__input">
              <input
                type="email"
                name="email"
                onChange={updateEmailInput}
                value={emailData}
                placeholder="Your Email Address"
              />
              <HashLink onClick={contactUs} smooth to="#contact"><button type="button"> Get Started</button></HashLink>
            </div>
          </div>

          <div className="cuckoo__header-image">
            <img src={space} />
          </div>
        </div>
      </div>
      <Program />
      <GiftCard />
      <Stocks />
      <Travel />
      <Features />
      <Developer />
      <Contact />
      <Footer />
    </>
  );
};

export default Header;
