export const orderjson = `{
    "id": "61e69015-8549-4bfd-b9c3-01e75843f47d",
    "client_order_id": "eb9e2aaa-f71a-4f51-b5b4-52a6c565dad4",
    "created_at": "2021-03-16T18:38:01.942282Z",
    "updated_at": "2021-03-16T18:38:01.942282Z",
    "submitted_at": "2021-03-16T18:38:01.937734Z",
    "order_status": "completed",
    "transactionValueAmount": 242,
    "rewardsCurrencyCode": "USD",
    "currencyCode": "US Dollars",
    "shipping": {
        "address": {}
    },
    "order_items" :
        [
            {                
                "rewardsItemIdentifier": "RWRDUSSTOCKS1",
                "rewardsItemName": "US Stocks",
                "filled_at": null,
                "expired_at": null,
                "canceled_at": null,
                "failed_at": null,
                "replaced_at": null,
                "replaced_by": null,
                "replaces": null,
                "asset_id": "b0b6dd9d-8b9b-48a9-ba46-b9d54906e415",
                "symbol": "AAPL",
                "asset_class": "us_equity",
                "notional": "500",
                "qty": null,
                "filled_qty": "0",
                "filled_avg_price": null,
                "order_class": "",
                "order_type": "market",
                "type": "market",
                "side": "buy",
                "time_in_force": "day",
                "limit_price": null,
                "stop_price": null,
                "status": "accepted",
                "extended_hours": false,
                "legs": null,
                "trail_percent": null,
                "trail_price": null,
                "hwm": null
            }
        ],
    "commission": {
        "type": "percent",
        "value": "5",
        "amount": "trade_value",
        "calculated_value": 1.25
    }
  }`;

export const accountjson = `{
    "id": "7ccfd029-9b91-40d0-9b4c-f928385af666",
    "rewards_account_ref_number": "808971365",
    "status": "SUBMITTED",
    "currency": "USD",
    "created_at": "2022-08-16T20:19:20.547306Z",
    "contact": {
        "email_address": "cuckoo@example.com",
        "phone_number": "555-666-7788",
        "street_address": [
            "20 N San Mateo Dr"
        ],
        "city": "San Mateo",
        "state": "CA",
        "postal_code": "94401"
    },
    "identity": {
        "given_name": "John",
        "family_name": "Doe",
        "date_of_birth": "1990-01-01",
        "tax_id_type": "USA_SSN",
        "country_of_citizenship": "USA",
        "country_of_birth": "USA",
        "country_of_tax_residence": "USA",
        "funding_source": [
            "employment_income"
        ],
        "visa_type": null,
        "visa_expiration_date": null,
        "date_of_departure_from_usa": null,
        "permanent_resident": null
    },
    "disclosures": {
        "is_control_person": false,
        "is_affiliated_exchange_or_finra": false,
        "is_politically_exposed": false,
        "immediate_family_exposed": false,
        "is_discretionary": false
    },
    "agreements": [
        {
            "rewards_agreement": "rewards_agreement",
            "signed_at": "2020-09-11T18:13:44Z",
            "ip_address": "185.13.21.99",
            "revision": "04.2021.10"
        }
    ],
    "documents": [
        {
            "document_type": "identity_verification",
            "document_sub_type": "passport",
            "id": "91098d44-c384-4388-a410-24616ce1c6e4",
            "content": "https://s3.amazonaws.com/stg.alpaca.markets/documents/accounts/7ccfd029-9b91-40d0-9b4c-f928385af666/af49b170-a4ba-4592-8036-db43964756c0.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJLDF4SCWSL6HUQKA%2F20220816%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220816T202138Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=a22b2eb92e373fd4146d10a4707765d35042f3cbd7c319341a718d9d823f5350",
            "created_at": "2022-08-16T20:19:21.333788Z"
        }
    ],
    "trusted_contact": {
        "given_name": "Jane",
        "family_name": "Doe",
        "email_address": "jane.doe@example.com"
    },
    
    "rewards_configurations":{
        "order_min_threshold": null,
        "order_max_threshold": null,
        "daily_threshold": null,
        "permitted_redemption_types": {
            "stocks": "yes",
            "crypto": "yes",
            "gift_carrd": null,
    },
    "permitted_redemption_asset_list": {
        "stocks": [null],
        "crypto": [null],
        "gift_carrd": [null],
    },
    "default_redemption_asset_list": {
        "stocks": [null],
        "crypto": [null],
        "gift_carrd": [null],
    },
    "client_product": {
        "rewards_program_name": [null],
        "rewards_category_code": [null],
        "product_type": [null],
        "product_name": [null],
        "product_code": [null],
    },
    "partner_accounts": {
        "stocks": {
            "partner_name": [null],
            "paartner_code": [null],
            "account_id": [null],
            "account_status": [null],
            "created_at": "2022-08-16T20:19:20.547306Z",
            "partner_account_configurations": null
        }
    },

    }
}`;
