import React from 'react';
import giftcCardmage from '../../assets/giftCard.png';
import './giftCard.css';

const GiftCard = () => (
  <div className="cuckoo__giftCard section__padding" id="giftCard">
    <div className="cuckoo__giftCard-image">
      <img src={giftcCardmage} alt="giftcCard" />
    </div>
    <div className="cuckoo__giftCard-content">
      <h4>Gift Cards</h4>
      <h1 className="gradient__text">Award Digital or <br /> Physical Gift cards</h1>
      <div><p>Start rewarding your customers with digital gift cards of the stores of their choice.</p>
        <div>
          <p>Gift cards are one of the most used rewards type in the industry.</p>
          <p>Setting up gift cards in the rewards program is easiest solution to start with.</p>
          <p>It saves money to both you and your customers.</p>
        </div>
      </div>

      <h4>Want to understand how to set-up gift cards rewards programs, request a demo today.</h4>
    </div>
  </div>
);

export default GiftCard;
