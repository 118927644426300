import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Sign up bonus',
    text: 'Sign up bonus ponts is the most used method to attract users and let them engaage with your awesome rewards product.',
  },
  {
    title: 'Spend and get rewards',
    text: 'Reward your customers with bonus points when they complete their first activity with your product. This ensures that you are not stuck with only app downloads, but customer engage and eperience your product and reward benefits, right after they join you',
  },
  {
    title: 'Anniversary Rewards',
    text: 'It is a big deal when a customer stays with you for a year. Celeberate with them by awarding them an experience or reward points. They will never forget it.',
  },
  {
    title: 'Transaction rewards',
    text: 'Last but not the least, who doesn\'t love rewards with each transaction. Celeberate each transaction with a reward and let it be better than just a cash back.',
  },
];

const Features = () => (
  <div className="cuckoo__features section__padding" id="features">
    <div className="cuckoo__features-heading">
      <h1 className="gradient__text">Rewards programs are key to acquire new customers and keep them engaged so that they keep coming back to use your banking services.</h1>
      <p>Gamify rewards relevant to specific customer journey</p>
    </div>
    <div className="cuckoo__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
