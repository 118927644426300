import React from 'react';
import './program.css';

const Program = () => (
  <div className="cuckoo__program" id="program">
    <div>
      <div className="cuckoo__program-content section__padding">
        <h1 className="gradient__text">Drive Program value</h1>
        <div>
          <p>Cash back is the most expensive and the least value options for rewards redemption. Don&apos;t get stuck in Cash Back.  Our program is designed to drive customers to use points for more valuable and cost effective redemption options.</p>
          <p>Join our rewards network and customize your rewards program to provide a better value and convnience. Motivate customers to consolidate all their business with your company, rather than use two or three providers.</p>
          <p> </p>
        </div>
        <div>
          <div>
            <p> . </p>
            <p>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Program;
