import React from 'react';
import travelImage from '../../assets/travel.png';
import './travel.css';

const Travel = () => (
  <div className="cuckoo__travel section__padding" id="travel">
    <div className="cuckoo__travel-image">
      <img src={travelImage} alt="travel" />
    </div>
    <div className="cuckoo__travel-content">
      <h4>Travel</h4>
      <h1 className="gradient__text">Flights, hotels, experiences <br /> car rental and more</h1>
      <div>
        <p>
          Let your nomad customer redeem reward points for travel, domestic or international. They get the best options and prices with the industry leadng travel portals.
        </p>
        <div>
          <p> Customers can build their itinerary or chose individual bookings.</p>
          <p> Instant confirmations, and full customer service to support before, during and after travel.</p>
        </div>
      </div>

      <h4>Partner with us and your nomad customers will never leave you.</h4>
    </div>

  </div>
);

export default Travel;
