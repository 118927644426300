import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import './contact.css';

const Contact = () => {
  const location = useLocation();
  const [cookies, setCookie] = useCookies();
  console.log('in contact reading Email passed from header: ', location.state);
  console.log('in contact reading cookies set from header: ', cookies.emailid);
  const [isThankShown, setIsThankShown] = useState(false);
  const [isContactShown, setIsContactShown] = useState(true);
  const [formData, setFormData] = useState({});
  if (!formData.email && cookies.emailid) {
    setFormData({ email: cookies.emailid });
  }
  // const emailid1 = cookies.emailid;
  /* if (emailid1) {
    formData.email = emailid1;
    emailid1 = '';
  } */

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  async function sendEmail() {
    try {
      // formData.email will be set as the document id in the database
      const docRef = await setDoc(doc(db, 'contactform', formData.email), {
        id: formData.email,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        company: formData.company,
        message: formData.message,
        time: new Date(),
      });

      console.log('in sendEmil, Document written with ID: ', docRef.name, formData.name);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    setIsThankShown(true);
    setIsContactShown(false);
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setCookie('emailid', '', { path: '/' });
  };

  const TheThank = (
    <div className="cuckoo__contact section__padding">
      <div>
        <div className="cuckoo__contact-circle">
          <div className="cuckoo__contact-checkmark" />
        </div>
        <h1 className="gradient__text">Thank you for sending the details over! </h1>
        <h2 className="gradient__text">You will hear from us shortly</h2>
      </div>
    </div>
  );

  const TheForm = (
    <div>
      <div className="cuckoo__contact section__padding" id="contact">
        <div className="cuckoo__contact-heading">
          <h1 className="gradient__text">Do you want to improve your customer retention?</h1>
        </div>
        <form className="cuckoo__contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={updateInput}
            value={formData.name || ''}
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            onChange={updateInput}
            value={formData.email || ''}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            onChange={updateInput}
            value={formData.phone || ''}
          />
          <input
            type="text"
            name="company"
            placeholder="Name"
            onChange={updateInput}
            value={formData.company || ''}
          />
          <textarea
            type="text"
            name="message"
            placeholder="Tell us something you want to talk about"
            onChange={updateInput}
            value={formData.message || ''}
          />
          <button type="submit">Let&apos;s Talk</button>
        </form>
      </div>
    </div>
  );

  return (
    <div>
      {isContactShown && TheForm}
      {isThankShown && TheThank}
    </div>
  );
};

export default Contact;
