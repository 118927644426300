import React from 'react';
// import { Program, GiftCard, Header, Stocks, Travel, Features, Developer, Contact, Footer } from './containers';
import { Header } from './containers';
// import { Navbar } from './components';
import './App.css';

const App = () => (
  <div className="App">
    <Header />
    {/* <Program />
    <GiftCard />
    <Stocks />
    <Travel />
    <Features />
    <Developer />
    <Contact />
    <Footer /> */}
  </div>
);

export default App;
