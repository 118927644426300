import React from 'react';
import stocksImage from '../../assets/stocks.png';
import './stocks.css';

const Stocks = () => (
  <div className="cuckoo__stocks section__padding" id="stocks">
    <div className="cuckoo__stocks-content">
      <h4>US Stocks</h4>
      <h1 className="gradient__text">Automated Invesment of <br /> rewards in US stocks</h1>
      <div><p>Lead the rewards innovation with stocks awards as an option.</p>
        <div>
          <p> Customers purchase a stock of their choice.</p>
          <p> Automated investment in US stocks real-time.</p>
        </div>
      </div>

      <h4>Cuckoo takes care of all the regulatory burden, hit us up today to know more.</h4>
    </div>
    <div className="cuckoo__stocks-image">
      <img src={stocksImage} alt="stocks" />
    </div>
  </div>
);

export default Stocks;
