import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-json';

import 'react-tabs/style/react-tabs.css';
import Code from '../../Code';
import './developer.css';
import { orderjson, accountjson } from './data.js';

const Developer = () => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="cuckoo__developer section__padding" id="developer">
      <div className="cuckoo__developer-content">
        <h4>API Objects</h4>
        <h1 className="gradient__text">Easy to Integrate APIs<br /> Devs would love</h1>
        <div><p>Two simple APIs to make it all happen.</p>
          <div>
            <p> Search API allows you to search for all options to redeem.</p>
            <p> Place order API to place any type of redemption order, specify option for the order execution to customize user experience.</p>
          </div>
        </div>

        <h4><a href="/api-docs.html">Click here for API Documentation</a></h4>
      </div>
      <div className="cuckoo__developer-json ">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Rewards Order</Tab>
            <Tab>Rewards Account</Tab>
          </TabList>

          <TabPanel>
            <Code code={orderjson} language="json" />
          </TabPanel>
          <TabPanel>
            <Code code={accountjson} language="json" />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Developer;
