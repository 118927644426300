/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { HashLink } from 'react-router-hash-link';
import logo from '../../logo.svg';
import './navbar.css';

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="cuckoo__navbar">
      <div className="cuckoo__navbar-links">
        <div className="cuckoo__navbar-links_logo">
          <a href="/#home"><img src={logo} /></a>
        </div>
        <div className="cuckoo__navbar-links_container">
          <p><a href="/#home">Home</a></p>
          <p><a href="/#program">Product</a></p>
          <p><a href="/#features">Reward Types</a></p>
          <p><a href="/#developer">Developer APIs</a></p>
        </div>
      </div>
      <div className="cuckoo__navbar-sign">
        <HashLink smooth to="/#contact"><button type="button">Request demo</button></HashLink>
      </div>
      <div className="cuckoo__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="cuckoo__navbar-menu_container scale-up-center">
            <div className="cuckoo__navbar-menu_container-links">
              <p><a href="/#home">Home</a></p>
              <p><a href="/#program">Product</a></p>
              <p><a href="/#features">Reward Types</a></p>
              <p><a href="/#developer">Developer APIs</a></p>
            </div>
            <div className="cuckoo__navbar-menu_container-links-sign">
              <HashLink smooth to="/#contact"><button type="button">Request demo</button></HashLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
